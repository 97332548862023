import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { MyOvertime } from '../models/aliz-vacation'
import { useMyOvertimeData } from './use-bq-tabledata'

export const getDailyWorkHours = (date: DateTime, overTimeData: MyOvertime[]) => {
  const monthData = overTimeData.find((row) => date.hasSame(DateTime.fromISO(row.month), 'month'))
  return Number(monthData?.planned_workhours ?? 0) / Number(monthData?.worked_day ?? 1)
}

export const useDailyWorkHours = (date: DateTime) => {
  const overtime = useMyOvertimeData()
  return useMemo(() => getDailyWorkHours(date, overtime.data?.rows ?? []), [overtime.data, date.toISODate()])
}

export const isToday = (date: DateTime) => {
  const today = DateTime.local()
  return date.hasSame(today, 'day')
}
