import { initializeApp } from 'firebase/app'
import {
  browserPopupRedirectResolver,
  browserSessionPersistence,
  getRedirectResult,
  GoogleAuthProvider,
  initializeAuth,
  signInWithPopup,
  signInWithRedirect,
  UserCredential,
} from 'firebase/auth'
import { useQuery, useQueryClient } from 'react-query'
import { persistReactQueryIndexedDBStorage } from '../cache/persist-with-indexeddb'

const TOKEN_EXPIRATION = 1000 * 60 * 55 // 55 minutes

const appStartTime = Date.now()
const redirectIndicator = !!new URL(window.location.href).searchParams.get('redirected')
const location = new URL(window.location.href)
location.searchParams.delete('redirected')
window.history.pushState({}, '', location.href)
const redirectAllowed = () => Date.now() - appStartTime > TOKEN_EXPIRATION || !redirectIndicator

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyBRKIoue_W3FeO539LH-8YnnsDEjHq8bik',
  authDomain: 'sipi-dev.firebaseapp.com',
})

const firebaseAuth = initializeAuth(firebaseApp, {
  persistence: browserSessionPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
})

const createAuthProvider = () => {
  const provider = new GoogleAuthProvider()
  provider.addScope('https://www.googleapis.com/auth/bigquery.readonly')
  return provider
}

const accessTokenFromResult = (result: UserCredential | null) => {
  if (!result) {
    return undefined
  }
  const credential = GoogleAuthProvider.credentialFromResult(result)
  return credential?.accessToken
}

export const signIn = async () => {
  const redirectUrl = new URL(window.location.href)
  redirectUrl.searchParams.set('redirected', 'true')
  window.history.pushState({}, '', redirectUrl.href)

  if (!redirectIndicator) {
    await persistReactQueryIndexedDBStorage()
    await signInWithRedirect(firebaseAuth, createAuthProvider())
  } else {
    throw new Error('Attempted to redirect after a redirect!')
  }
}

const getAccessToken = async () => {
  let token: string | undefined
  let redirectError: Error | undefined
  let popupError: Error | undefined

  console.time('auth')
  try {
    token = accessTokenFromResult(await getRedirectResult(firebaseAuth))
    if (!token && redirectAllowed()) {
      await signIn()
    }
  } catch (e) {
    redirectError = new Error('Getting access token from redirect failed', { cause: e as Error })
  } finally {
    if (!token) {
      try {
        token = accessTokenFromResult(await signInWithPopup(firebaseAuth, createAuthProvider()))
      } catch (e) {
        popupError = new Error('Getting access token from popup failed', {
          cause: new Error((e as Error).message, { cause: redirectError }),
        })
      }
    }
  }

  if (!token) {
    const authError = new Error('Sign in failed', { cause: popupError ?? redirectError })
    console.error(authError)
    throw authError
  }

  console.timeEnd('auth')
  return token
}

export const useGoogleAccessToken = () => {
  const client = useQueryClient()
  return useQuery('fire-google-access-token', getAccessToken, {
    cacheTime: TOKEN_EXPIRATION,
    staleTime: TOKEN_EXPIRATION,
    onSettled: () => client.invalidateQueries('bq-query'),
  })
}
