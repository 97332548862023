import { Grid, LinearProgress } from '@mui/material'
import React from 'react'
import { QueryErrorAlert } from '../shared/components/query-error-alert'
import { AppHeader } from '../shared/components/header'
import { useGoogleAccessToken } from '../shared/hooks/use-firebase-auth'

const unwrapErrorCause = (error: Error) =>
  error.cause ? [error.message, unwrapErrorCause(error.cause)] : [error.message]

export const Login = () => {
  const token = useGoogleAccessToken()
  return (
    <Grid container spacing={2}>
      <AppHeader />

      <Grid item xs={12}>
        {token.isFetching ? <LinearProgress variant="indeterminate" /> : null}
        <QueryErrorAlert />
      </Grid>
    </Grid>
  )
}
