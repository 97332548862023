import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Button, Card, CardHeader, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import React, { useState, useTransition } from 'react'
import { DayLegend } from '../calendar/day/day-legend'
import { Year } from '../calendar/year'
import { Emoji } from '../shared/components/emoji'
import { AppHeader } from '../shared/components/header'
import { QueryErrorAlert } from '../shared/components/query-error-alert'
import { useMyOvertimeData, useMyVacationUsageData } from '../shared/hooks/use-bq-tabledata'
import { useYearOptions } from '../shared/hooks/use-year-options'

const numFormat = new Intl.NumberFormat(window.navigator.language, {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

const YearSelector = ({ year, setYear }) => {
  const options = useYearOptions()
  const [pending, startTransition] = useTransition()

  const currentIndex = options.indexOf(year)
  const hasPrev = currentIndex > 0
  const hasNext = currentIndex < options.length - 1

  return (
    <Grid item xs={1}>
      <Card>
        <CardHeader
          avatar={<Emoji emoji="🗓" size="32px" />}
          title={
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <IconButton
                size="small"
                color="primary"
                disabled={pending || !hasPrev}
                onClick={() => startTransition(() => setYear(options[currentIndex - 1]))}
              >
                <ArrowBackIosIcon />
              </IconButton>

              <Typography variant="h4" color="primary">
                {year}
              </Typography>

              <IconButton
                size="small"
                color="primary"
                disabled={pending || !hasNext}
                onClick={() => startTransition(() => setYear(options[currentIndex + 1]))}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Stack>
          }
          subheader="Year"
        />
      </Card>
    </Grid>
  )
}

const StatCard = (props: { emoji: string; label: string; value: string | undefined }) => {
  return (
    <Grid item xs={1}>
      <Card>
        <CardHeader
          avatar={<Emoji emoji={props.emoji} size="32px" />}
          title={
            <Typography variant="h4" color="primary">
              {props.value}
            </Typography>
          }
          subheader={props.label}
        />
      </Card>
    </Grid>
  )
}

const VacationSummary = (props: { year: number }) => {
  const vacations = useMyVacationUsageData()
  const overtime = useMyOvertimeData()
  const december = DateTime.local(props.year, 12, 1).toISODate()
  const decMonthData = overtime.data?.rows.find((row) => row.month === december)
  const overTimeHours = decMonthData?.cumulativ_overtime

  const currentMonth = DateTime.now().startOf('month')
  const currentMonthData = overtime.data?.rows.find((row) => row.month === currentMonth.toISODate())
  const currentMonthTrackedWorkhours = numFormat.format(Number(currentMonthData?.tracked_workhours))
  const currentMonthPlannedWorkhours = numFormat.format(Number(currentMonthData?.planned_workhours))

  if (vacations.isLoading || !vacations.data) {
    return (
      <>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((idx) => (
          <Grid item xs={1} key={'' + idx}>
            <Skeleton width="100%" height="100px" animation="wave" />
          </Grid>
        ))}
      </>
    )
  }

  const vacationData = vacations.data?.rows.find((row) => row.year === '' + props.year)

  return (
    <>
      <StatCard emoji="📅" label="Yearly Vacation" value={vacationData?.yearly_vacations} />
      <StatCard emoji="🏖" label="Used/Planned Vacation" value={vacationData?.vacation} />
      <StatCard emoji="🏖" label="Remaining Vacation" value={vacationData?.remaining_vacations} />
      <StatCard emoji="🤮" label="Sick Leave" value={vacationData?.sick_leave} />
      <StatCard emoji="🚫💰" label="Unpaid Leave" value={vacationData?.unpaid_leave} />
      <StatCard emoji="⌛🌃" label="Overtime Vacation" value={vacationData?.overtime_vacation} />
      <StatCard emoji="📜" label="Special leave" value={vacationData?.special_leave} />
      <StatCard emoji="⏳" label="Cumulative overtime hours" value={numFormat.format(Number(overTimeHours))} />
      {props.year === currentMonth.year ? (
        <StatCard
          emoji="⏱"
          label="Workhours logged this month"
          value={`${currentMonthTrackedWorkhours}/${currentMonthPlannedWorkhours}`}
        />
      ) : null}
    </>
  )
}

export const DashBoard = () => {
  const [year, setYear] = useState(DateTime.now().year)

  return (
    <Grid container spacing={2}>
      <AppHeader />

      <Grid item xs={12}>
        <QueryErrorAlert />
      </Grid>

      <Grid
        item
        container
        xs={12}
        spacing={2}
        sx={{ p: 1 }}
        columns={{
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 6,
        }}
      >
        <YearSelector year={year} setYear={setYear} />
        <VacationSummary year={year} />
      </Grid>
      <Grid item xs={12} sx={{ p: 1 }}>
        <Year year={year} />
      </Grid>

      <Grid item xs={12} md={6} lg={3} xl={2}>
        <DayLegend />
      </Grid>

      <Grid item xs={12} md={6} lg={3} xl={2}>
        <Button
          color="secondary"
          variant="text"
          startIcon={<OpenInNewIcon />}
          target="_blank"
          href="https://wiki.aliz.ai/view/1KyUK73lKemfl9JTZMUFcv0QOM_Yu3qxgS0rZ3Pn0hE0?n=Day-Off_planning_administration_%28Vacation_Sick_Leave_Special_Leave_eligibility%29"
        >
          Vacation Policy
        </Button>
        <Button
          color="secondary"
          variant="text"
          startIcon={<OpenInNewIcon />}
          target="_blank"
          href="https://wiki.aliz.ai/view/1nxn9ORdQPTvTICbofQAA0p0PrGMs0xqOsPaEH9lO-J0?n=Time_tracking_and_monitoring_policy"
        >
          Time Tracking Policy
        </Button>
        <Button
          color="secondary"
          variant="text"
          startIcon={<OpenInNewIcon />}
          target="_blank"
          href="https://datastudio.google.com/u/0/reporting/4dc03e3e-9381-4f47-8fb2-ffed37725ca5/page/RwlWC"
        >
          Aliz Vacation and overtime looker dashboard
        </Button>
      </Grid>
    </Grid>
  )
}
