import { Typography } from '@mui/material'
import React from 'react'

const numFormat = new Intl.NumberFormat(window.navigator.language, {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  signDisplay: 'exceptZero',
})

export const OvertimeLabel = (props: { overtime: number | null | undefined; color?: string }) => {
  if (!props.overtime || props.overtime === 0) {
    return null
  }

  return (
    <Typography variant="caption" color={props.color} sx={{ fontWeight: 'bold', lineHeight: 1.2 }}>
      {numFormat.format(props.overtime)}
    </Typography>
  )
}
