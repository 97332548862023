import { Grid, AppBar, Toolbar, Typography } from '@mui/material'
import React from 'react'

export const AppHeader = () => {
  return (
    <Grid item xs={12}>
      <AppBar position="static" enableColorOnDark>
        <Toolbar variant="dense">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Aliz Vacation Calendar
          </Typography>
        </Toolbar>
      </AppBar>
    </Grid>
  )
}
