import { Card, CardContent, CardHeader, Grid, Skeleton } from '@mui/material'
import { DateTime } from 'luxon'
import React from 'react'
import { useMyOvertimeData } from '../shared/hooks/use-bq-tabledata'
import { monthChangeIndex, useWeeklyOvertimeOfYear } from '../shared/hooks/use-weekly-overtime-of-year'
import { Day, DayPaper } from './day'
import { OvertimeLabel } from './day/overtime-label'

const numFormat = new Intl.NumberFormat(window.navigator.language, {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

const MonthSkeleton = () => (
  <Card sx={{ width: '100%', height: '100%' }}>
    <CardHeader title={<Skeleton animation="wave" />} subheader={<Skeleton animation="wave" />} />
    <CardContent>
      <Skeleton width="100%" height="260px" animation="wave" />
    </CardContent>
  </Card>
)

const DayGridItem = (props: { year: number; month: number; day: number; weeklyOverTime: Record<number, number> }) => {
  const date = DateTime.local(props.year, props.month, props.day)
  const isEndOfMonth = date.endOf('month').hasSame(date, 'day')
  const isEndOfWeek = date.endOf('week').hasSame(date, 'day')
  const shouldDisplayWeeklyOvertime = isEndOfMonth || isEndOfWeek

  const offset = 7 - date.weekday

  const getWeeklyOvertime = () => {
    return (
      props.weeklyOverTime[date.weekNumber] ?? props.weeklyOverTime[monthChangeIndex(props.month, date.weekNumber)] ?? 0
    )
  }

  return (
    <>
      <Grid item xs={1}>
        <Day year={props.year} month={props.month} day={props.day} />
      </Grid>
      {isEndOfMonth && offset ? <Grid item xs={offset} /> : null}
      {shouldDisplayWeeklyOvertime ? (
        <Grid item xs={1}>
          <DayPaper elevation={2}>
            <OvertimeLabel overtime={getWeeklyOvertime()} />
          </DayPaper>
        </Grid>
      ) : null}
    </>
  )
}

export const Month = (props: { year: number; month: number }) => {
  const date = DateTime.local(props.year, props.month, 1)
  const offset = date.startOf('month').weekday - 1

  const overtime = useMyOvertimeData()
  const weeklyOverTime = useWeeklyOvertimeOfYear(props.year)

  if (overtime.isLoading || weeklyOverTime.isLoading) {
    return <MonthSkeleton />
  }

  const monthData = overtime.data?.rows.find((row) => row.month === date.toISODate())

  const weekDayLabels = ['M', 'T', 'W', 'T', 'F', 'S', 'S', '⏳']

  return (
    <Card sx={{ width: '100%', height: '100%' }}>
      <CardHeader
        title={date.toFormat('LLLL')}
        subheader={
          <>
            {numFormat.format(Number(monthData?.tracked_workhours))}/
            {numFormat.format(Number(monthData?.planned_workhours))} (
            {numFormat.format(Number(monthData?.cumulativ_overtime))}⏳)
          </>
        }
      />
      <CardContent>
        <Grid container spacing={1} columns={8}>
          {weekDayLabels.map((label, idx) => (
            <Grid item key={label + idx} xs={1} sx={{ textAlign: 'center' }}>
              {label}
            </Grid>
          ))}

          {offset ? <Grid item xs={offset} /> : null}

          {Array.from(Array(date.daysInMonth).keys()).map((i) => (
            <DayGridItem
              key={i}
              year={props.year}
              month={props.month}
              day={i + 1}
              weeklyOverTime={weeklyOverTime.data ?? {}}
            />
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
}
