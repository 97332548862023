import { range } from 'lodash'
import { DateTime, MonthNumbers } from 'luxon'
import { useQuery } from 'react-query'
import { useDetailedWorkData, useMyOvertimeData } from './use-bq-tabledata'
import { getDailyWorkHours, isToday } from './use-daily-work-hours'
import { DayDetailedData } from '../models/aliz-vacation'

export const useWeeklyOvertimeOfYear = (year: number) => {
  const allWorkData = useDetailedWorkData()
  const overtime = useMyOvertimeData()

  const getWeeklyOvertime = (rawDayData: DayDetailedData[], week: number, month: MonthNumbers) => {
    const weekDayData = rawDayData.filter((row) => DateTime.fromISO(row.date).month === month)

    const workDayCount = weekDayData
      .filter((row) => JSON.parse(row?.workday ?? 'false'))
      .filter((row) => !row.out_of_office_type).length
    const totalOvertime = weekDayData.reduce((acc, row) => acc + Number(row.workhours), 0)

    const dailyWorkHours = getDailyWorkHours(
      DateTime.fromObject({ weekYear: year, weekNumber: week }),
      overtime.data?.rows ?? []
    )

    const baseHours = dailyWorkHours * workDayCount
    return totalOvertime - baseHours
  }

  return useQuery(
    [
      `weekly-overtime-of-year`,
      year,
      allWorkData.data?.response.jobReference.jobId,
      overtime.data?.response.jobReference.jobId,
    ],
    async () => {
      const yearDate = DateTime.local(year, 1, 1)
      const firstWeek = 1
      const lastWeek = yearDate.endOf('year').weekNumber
      const weeks = range(firstWeek, lastWeek + 1)

      const today = DateTime.local()
      const yearData =
        allWorkData.data?.rows
          .filter((row) => DateTime.fromISO(row.date).hasSame(yearDate, 'year'))
          .filter((row) => DateTime.fromISO(row.date).toMillis() < today.toMillis()) ?? []

      const weeklyOvertime: Record<number, number> = {}
      weeks.forEach((week) => {
        const weekDayData = yearData.filter(
          (row) => DateTime.fromISO(row.date).weekNumber === week && !isToday(DateTime.fromISO(row.date))
        )

        if (weekDayData.length > 0) {
          const firstMonth = DateTime.fromISO(weekDayData[0].date).month
          const lastMonth = DateTime.fromISO(weekDayData[weekDayData.length - 1].date).month
          if (firstMonth === lastMonth) {
            weeklyOvertime[week] = getWeeklyOvertime(weekDayData, week, firstMonth)
          } else {
            weeklyOvertime[monthChangeIndex(firstMonth, week)] = getWeeklyOvertime(weekDayData, week, firstMonth)
            weeklyOvertime[monthChangeIndex(lastMonth, week)] = getWeeklyOvertime(weekDayData, week, lastMonth)
          }
        }
      })

      return weeklyOvertime
    },
    {
      enabled: !!allWorkData.data && !!overtime.data,
    }
  )
}

export const monthChangeIndex = (month: number, week: number) => {
  return month * 100 + week
}
