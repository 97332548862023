import { Card, CardHeader, CardContent, ListItemAvatar, List, ListItem, ListItemText } from '@mui/material'
import React from 'react'
import { DayItem, DayPaper } from '../day'
import { OvertimeLabel } from './overtime-label'

export const DayLegend = () => {
  return (
    <Card>
      <CardHeader title="Legend" />
      <CardContent>
        <List>
          <ListItem>
            <ListItemAvatar>
              <DayItem calendarDay={12} workDay />
            </ListItemAvatar>
            <ListItemText primary="Workday" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <DayItem calendarDay={12} workDay={false} />
            </ListItemAvatar>
            <ListItemText primary="Non working day" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <DayItem calendarDay={12} overTime={2} workDay />
            </ListItemAvatar>
            <ListItemText primary="Overtime" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <DayItem calendarDay={12} workDay outOfOfficeType="vacation" />
            </ListItemAvatar>
            <ListItemText primary="Vacation" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <DayItem calendarDay={12} workDay outOfOfficeType="sick_leave" />
            </ListItemAvatar>
            <ListItemText primary="Sick Leave" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <DayItem calendarDay={12} workDay outOfOfficeType="overtime_vacation" />
            </ListItemAvatar>
            <ListItemText primary="Overtime Vacation" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <DayItem calendarDay={12} workDay outOfOfficeType="unpaid_leave" />
            </ListItemAvatar>
            <ListItemText primary="Unpaid Leave" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <DayItem calendarDay={12} workDay outOfOfficeType="special_leave" />
            </ListItemAvatar>
            <ListItemText primary="Special Leave" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <DayItem calendarDay={12} overTime={1.5} workDay outOfOfficeType="vacation" />
            </ListItemAvatar>
            <ListItemText primary="Vacation with overtime" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <DayPaper>
                <OvertimeLabel overtime={1.5} />
              </DayPaper>
            </ListItemAvatar>
            <ListItemText primary="Weekly Overtime summary" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Click on any day to go to Toggl!" />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}
