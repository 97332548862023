import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './app/app'

console.log('App Start')

const root = createRoot(document.getElementById('root') as Element)
root.render(<App />)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(new URL('./service-worker.ts', import.meta.url), { type: 'module' })
  })
}
