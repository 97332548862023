import { createTheme, ThemeOptions, useMediaQuery } from '@mui/material'
import createPalette from '@mui/material/styles/createPalette'
import { useMemo } from 'react'

const customPalette = (main: string) =>
  createPalette({ primary: { main }, tonalOffset: 0.2, contrastThreshold: 4.5 }).primary

const defaultTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#8cac9c',
    },
    secondary: {
      main: '#f2d142',
    },
    warning: {
      main: '#f2d142',
    },
    error: {
      main: '#fd8f82',
    },
    info: {
      main: '#02394d',
    },
    workDay: customPalette('#33C6BF'),
    nonWorkingDay: customPalette('#C7A920'),
    outOfOffice: customPalette('#C748B0'),
    tonalOffset: 0.2,
    contrastThreshold: 3,
  },
}

export const useColorMode = <T>(light: T, dark: T) => (useMediaQuery('(prefers-color-scheme: dark)') ? dark : light)

export const useTheme = () => {
  const preferredMode = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light'
  const theme = useMemo(
    () =>
      createTheme({
        ...defaultTheme,
        palette: createPalette({
          ...defaultTheme.palette,
          mode: preferredMode,
        }),
      }),
    [preferredMode]
  )
  return theme
}
