import { CssBaseline, ThemeProvider } from '@mui/material'
import React, { StrictMode, useEffect, useState } from 'react'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { DashBoard } from './pages/dashboard'
import { Login } from './pages/login'
import { createIDBPersister } from './shared/cache/persist-with-indexeddb'
import { useGoogleAccessToken } from './shared/hooks/use-firebase-auth'
import { useTheme } from './shared/hooks/use-theme'

const queryCache = new QueryCache()
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24 * 2, // 24 hours
    },
  },
})

const clientCacheSetup = persistQueryClient({
  queryClient,
  persistor: createIDBPersister(),
  buster: '3',
})
// persistWithIndexedDB(queryClient)

const Content = () => {
  const token = useGoogleAccessToken()

  if (!token.data) {
    return <Login />
  }

  return <DashBoard />
}

export const App = () => {
  const theme = useTheme()

  const [cacheReady, setCacheReady] = useState(false)

  useEffect(() => {
    if (!cacheReady) {
      clientCacheSetup.then(() => {
        setCacheReady(true)
      })
    }
  }, [cacheReady])

  if (!cacheReady) {
    return null
  }

  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <Content />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </StrictMode>
  )
}
