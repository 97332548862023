import { Box } from '@mui/material'
import React from 'react'

export const Emoji = (props: { emoji: string; size?: string }) => (
  <Box
    component="div"
    sx={{
      fontFamily: 'Segoe UI Emoji',
      fontSize: props.size ?? '16px',
      width: '100%',
      textAlign: 'center',
      lineHeight: 1.2,
    }}
  >
    {props.emoji}
  </Box>
)
