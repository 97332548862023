import { Paper, Skeleton, styled, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import React, { useMemo, useState } from 'react'
import { Emoji } from '../shared/components/emoji'
import { useDetailedWorkData } from '../shared/hooks/use-bq-tabledata'
import { isToday, useDailyWorkHours } from '../shared/hooks/use-daily-work-hours'
import { DayDetailedData, OutOfOfficeType } from '../shared/models/aliz-vacation'
import { DayDialog } from './day/day-dialog'
import { OvertimeLabel } from './day/overtime-label'

const leaveEmoji: Record<OutOfOfficeType, string> = {
  vacation: '🏖',
  sick_leave: '🤮',
  overtime_vacation: '⌛',
  special_leave: '📜',
  unpaid_leave: '🚫',
}

const useOverTime = (dayData: DayDetailedData | undefined, date: DateTime) => {
  const dailyWorkHours = useDailyWorkHours(date)
  return useMemo(() => {
    if (!dayData) return null

    if (isToday(date)) {
      return 0
    }

    const workDay = JSON.parse(dayData?.workday ?? 'false')
    const workHours = Number.isNaN(dayData?.workhours) ? 0 : Number(dayData?.workhours)
    const baseHours = workDay && !dayData?.out_of_office_type && date < DateTime.local() ? dailyWorkHours : 0
    const overTime = workHours - baseHours
    return overTime
  }, [dayData, date.toISODate()])
}

const size = '32px'
export const DayPaper = styled(Paper)({
  width: size,
  height: size,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
})

interface DayItemProps {
  isToday?: boolean
  workDay: boolean
  outOfOfficeType?: OutOfOfficeType
  overTime?: number | undefined | null
  calendarDay: number
  onClick?: () => unknown
}

export const DayItem = (props: DayItemProps) => {
  const theme = useTheme()
  const { isToday, overTime, outOfOfficeType, calendarDay, workDay } = props

  let bgPalette = workDay ? theme.palette.workDay : theme.palette.nonWorkingDay
  if (outOfOfficeType) {
    bgPalette = theme.palette.outOfOffice
  }

  return (
    <DayPaper
      elevation={2}
      sx={{
        backgroundColor: bgPalette.main,
        ':hover': {
          backgroundColor: bgPalette.light,
        },
        ':active': {
          backgroundColor: bgPalette.dark,
        },
        transition: 'all 0.1s ease-in-out',
        outline: (theme) => (isToday ? `2px solid ${theme.palette.warning.main}` : 'none'),
        display: 'flex',
        flexDirection: 'column',
        cursor: props.onClick ? 'pointer' : 'default',
      }}
      onClick={props.onClick}
    >
      {outOfOfficeType ? <Emoji emoji={leaveEmoji[outOfOfficeType]} size={!!overTime ? '12px' : undefined} /> : null}
      <OvertimeLabel overtime={overTime} color={bgPalette.contrastText} />

      {!overTime && !outOfOfficeType ? (
        <Typography variant="body1" color={'#fff'} sx={{ opacity: 0.5 }}>
          {calendarDay}
        </Typography>
      ) : null}
    </DayPaper>
  )
}

export const Day = (props: { year: number; month: number; day: number }) => {
  const result = useDetailedWorkData()
  const date = DateTime.local(props.year, props.month, props.day)
  const isoDate = date.toISODate()

  const dayData = result.data?.rows.find((row) => row.date === isoDate)
  const workDay = JSON.parse(dayData?.workday ?? 'false')
  const overTime = useOverTime(dayData, date)

  const [open, setOpen] = useState(false)

  if (result.isLoading || !result.isSuccess) {
    return <Skeleton width={size} height={size} animation="wave" />
  }
  return (
    <>
      <DayItem
        isToday={isToday(date)}
        workDay={workDay}
        outOfOfficeType={dayData?.out_of_office_type}
        overTime={overTime}
        calendarDay={date.day}
        onClick={() => setOpen(true)}
      />
      <DayDialog date={date} open={open} handleClose={() => setOpen(false)} />
    </>
  )
}
