import { Dialog, DialogTitle, List, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import { DateTime } from 'luxon'
import GoogleIcon from '@mui/icons-material/Google'
import TimerIcon from '@mui/icons-material/Timer'
import React from 'react'

export const DayDialog = (props: { date: DateTime; open: boolean; handleClose: () => unknown }) => {
  const { date, open, handleClose } = props
  const isoDate = date.toISODate()

  const togglLink = `https://track.toggl.com/reports/detailed/2049184/from/${isoDate}/to/${isoDate}/`
  const gooogleCalLink = `https://calendar.google.com/calendar/r/day/${date.year}/${date.month}/${date.day}`

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{date.toLocaleString(DateTime.DATE_FULL)}</DialogTitle>
      <List>
        <ListItemButton component="a" href={togglLink} target="_blank" onClick={handleClose}>
          <ListItemAvatar>
            <TimerIcon />
          </ListItemAvatar>
          <ListItemText primary="Toggl" />
        </ListItemButton>
        <ListItemButton component="a" href={gooogleCalLink} target="_blank" onClick={handleClose}>
          <ListItemAvatar>
            <GoogleIcon />
          </ListItemAvatar>
          <ListItemText primary="Google Calendar" />
        </ListItemButton>
      </List>
    </Dialog>
  )
}
