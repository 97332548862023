import { Alert } from '@mui/material'
import React from 'react'
import { useQueryClient } from 'react-query'

const unwrapErrorCause = (error: Error) =>
  error.cause ? [error.message, ...unwrapErrorCause(error.cause)] : [error.message]

export const QueryErrorAlert = () => {
  const client = useQueryClient()
  const errors = client
    .getQueryCache()
    .findAll({ predicate: (query) => !!query.state.error })
    .map((query) => query.state.error as Error)

  if (!errors.length || !client.isFetching()) {
    return null
  }

  return (
    <>
      {errors.map((error, eidx) => (
        <Alert key={error.message + eidx} severity="error">
          {unwrapErrorCause(error).map((message, idx) => (
            <div key={message + idx}>{message}</div>
          ))}
        </Alert>
      ))}
    </>
  )
}
