import { useQuery } from 'react-query'
import { DayDetailedData, MyOvertime, MyVacationUsage } from '../models/aliz-vacation'
import { BqQueryResponse } from '../models/bigquery'
import { useGoogleAccessToken } from './use-firebase-auth'

const projectId = 'aliz-finance'
const datasetId = 'public'

export const useBqTableData = (tableId: string) => {
  const token = useGoogleAccessToken().data
  // const { token } = useContext(AuthContext)

  const result = useQuery(
    ['bq-table-data', tableId, token],
    async () =>
      fetch(
        `https://www.googleapis.com/bigquery/v2/projects/${projectId}/datasets/${datasetId}/tables/${tableId}/data`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
          },
        }
      ).then((res) => res.json()),
    { enabled: !!token }
  )
  return result
}

const queryRowTransform = <Row>(query: BqQueryResponse | undefined) => {
  const names = query?.schema?.fields.map((f) => f.name) ?? []
  return (
    (query?.rows
      ?.map((row) => names.map((name, idx) => [name, row.f[idx].v] as const))
      .map((rowEntries) => Object.fromEntries(rowEntries)) as unknown as Row[]) ?? []
  )
}

export const useBqQuery = <Row>(query: string) => {
  const tokenQuery = useGoogleAccessToken()
  const token = tokenQuery.data

  const result = useQuery<{ rows: Row[]; response: BqQueryResponse }, unknown>(
    ['bq-query', query, !!token, tokenQuery.isStale],
    async () => {
      try {
        const fetchResponse = await fetch(new URL('/api/queries', window.location.href).toString(), {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            projectId,
            query,
            useLegacySql: false,
          }),
        })
        if (fetchResponse.ok) {
          const response = (await fetchResponse.json()) as BqQueryResponse
          const rows = queryRowTransform<Row>(response)
          if (rows.length === 0) {
            throw new Error('No data received for ' + query)
          }
          return { rows, response }
        } else {
          throw new Error(fetchResponse.statusText)
        }
      } catch (e) {
        throw new Error('Failed get results for query: ' + query, { cause: e as Error })
      }
    },
    {
      enabled: !!token && !tokenQuery.isStale,
      cacheTime: 60 * 60 * 1000,
      staleTime: 60 * 60 * 1000,
      keepPreviousData: true,
    }
  )

  return result
}

export const useBqViewData = <Data>(viewId: string) => useBqQuery<Data>(`SELECT * FROM \`${viewId}\` LIMIT 2000`)

export const useDetailedWorkData = () =>
  useBqViewData<DayDetailedData>('aliz-finance.public.my_detailed_hours_and_vacations_report')

export const useMyOvertimeData = () => useBqViewData<MyOvertime>('aliz-finance.public.my_overtime')

export const useMyVacationUsageData = () => useBqViewData<MyVacationUsage>('aliz-finance.public.my_vacation_usage')
