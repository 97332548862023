import { Grid } from '@mui/material'
import React from 'react'
import { Month } from './month'

export const Year = (props: { year: number }) => {
  return (
    <Grid
      container
      spacing={2}
      columns={{
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 6,
      }}
    >
      {Array.from(Array(12).keys()).map((i) => (
        <Grid item xs={1} key={i}>
          <Month year={props.year} month={i + 1} />
        </Grid>
      ))}
    </Grid>
  )
}
