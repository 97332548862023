import { useMemo } from 'react'
import { useMyVacationUsageData } from './use-bq-tabledata'

export const useYearOptions = () => {
  const myVacationUsage = useMyVacationUsageData()

  return useMemo(() => {
    if (!myVacationUsage.isFetched) {
      return []
    }
    const yearSet = new Set<number>()
    myVacationUsage.data?.rows.forEach((row) => yearSet.add(Number(row.year)))
    return [...yearSet]
  }, [myVacationUsage.data, myVacationUsage.isFetched])
}
